
/**
 * Back To Top
 */
export const ButtonBackToTop = () => {
    const $button = document.querySelector('.kg-button--scroll');
    const windowHeight = window.innerHeight;

    if (!$button) return;

    window.addEventListener('scroll', (e) => {
        if ( document.documentElement.scrollTop >= windowHeight ) {
            $button.classList.add('is-visible');
        } else {
            $button.classList.remove('is-visible');
        }
    });
};
