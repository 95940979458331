import Menu from './modules/Menu';
import Breadcrumb from './modules/Breadcrumb';
import KGToggleNavigation from './modules/KGToggleNavigation';
import { ButtonBackToTop } from './modules/Buttons';
import InitUIkit from './modules/UIkit';

// CSS
import '../scss/styles.scss';

/**
 * Init Function on document Ready
 */
const initReady = () => {
    // Always scroll to top when page is loaded
    document.documentElement.scrollTo(0, 0);

    const KGMenu = new Menu( document.querySelector('.kg-menu') );

    // UIKit Functions
    InitUIkit();

    // Toggle Navigation
    KGToggleNavigation();

    // Breadcrumb
    Breadcrumb();
    // Butttons
    ButtonBackToTop();
};

// Document ready
document.addEventListener('DOMContentLoaded', initReady);



