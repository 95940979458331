import { calcWinsize, breakpoint } from '../Helpers';

// UIKit Core
import UIkit from 'uikit/dist/js/uikit-core';

// Uikit Components
import Tooltip from 'uikit/dist/js/components/tooltip';
import Lightbox from 'uikit/dist/js/components/lightbox';

// UIKit Icons
import Icons from 'uikit/dist/js/uikit-icons';

// Use Icons
UIkit.use(Icons);

// Activate Components
UIkit.component('tooltip', Tooltip);
UIkit.component('lightbox', Lightbox);

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

/* affiche la modal alert message (flashdata) si elle existe  */

function showAlertMessage() {
    var alertDiv = document.querySelector('.alert_message');
    if (alertDiv) {
        UIkit.modal(alertDiv).show();
    }
}

/**
 * Init Function on document Ready
 */
const InitUIkit = () => {
    showAlertMessage();
};

export default InitUIkit;
