import { breakpoint, calcWinsize } from '../Helpers';

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

/**
 * Toggle Header Offcanvas
 */
const KGToggleNavigation = () => {
    const $toggleMenu = document.querySelector('.kg-header .kg-button-burger');
    const $offcanvas = document.querySelector('#kg-offcanvas-navigation');

    if ( !$toggleMenu && !$offcanvas ) {
        return;
    }

    const $toggleMenuOpen = $toggleMenu.querySelector('.kg-button-burger__span-open');
    const $toggleMenuClose = $toggleMenu.querySelector('.kg-button-burger__span-close');

    function setOffcanvasTop() {
        const $header = document.querySelector('.kg-header');
        let headerHeight = $header.clientHeight;

        if ( winsize.width > breakpoint.medium ) {
            $offcanvas.style.setProperty('--kg-offcanvas-top', 'auto');
        } else {
            $offcanvas.style.setProperty('--kg-offcanvas-top', `${headerHeight}px` );
        }
    }

    /**
     * Toggle Canvas Attribute
     */
    function toggleOffcanvasAttribute() {
        if ( $offcanvas.getAttribute('aria-hidden') === 'false' ) {
            $offcanvas.setAttribute('aria-hidden', 'true');
        } else {
            $offcanvas.setAttribute('aria-hidden', 'false');
        }
    }

    /**
     * Toggle Button Attribute
     */
    function toggleButtonAttribute() {
        if ( $offcanvas.getAttribute('aria-hidden') === 'false' ) {
            // Toggle Button
            $toggleMenu.setAttribute('aria-expanded', 'true');
            // Toggle Open
            $toggleMenuOpen.setAttribute('aria-hidden', 'true');
            $toggleMenuOpen.setAttribute('hidden', '');
            // Toggle Close
            $toggleMenuClose.setAttribute('aria-hidden', 'false');
            $toggleMenuClose.removeAttribute('hidden');
        } else {
            $toggleMenu.setAttribute('aria-expanded', 'false');
            // Toggle Close
            $toggleMenuClose.setAttribute('aria-hidden', 'true');
            $toggleMenuClose.setAttribute('hidden', '');
            // Toggle Open
            $toggleMenuOpen.setAttribute('aria-hidden', 'false');
            $toggleMenuOpen.removeAttribute('hidden', '');
        }
    }

    /**
     * Resize Events
     */
    function resizeEvents() {
        if (winsize.width > breakpoint.medium) {
            $offcanvas.setAttribute('aria-hidden', 'false');
            $toggleMenu.removeAttribute('aria-expanded');
            $toggleMenu.setAttribute('aria-hidden', 'true');
            $toggleMenu.setAttribute('hidden', '');
        } else {
            $offcanvas.setAttribute('aria-hidden', 'true');
            $toggleMenu.setAttribute('aria-expanded', 'false');
            $toggleMenu.setAttribute('aria-hidden', 'false');
            $toggleMenu.removeAttribute('hidden');
        }
    }

    function init() {
        // Event On Toggle Menu click event
        $toggleMenu.addEventListener('click', (e) => {
            document.documentElement.classList.toggle('uk-overflow-hidden');
            $toggleMenu.classList.toggle('is-open');
            $offcanvas.classList.toggle('is-visible');
            setOffcanvasTop();
            toggleOffcanvasAttribute();
            toggleButtonAttribute();
        });
        resizeEvents();
    }

    // Resize Events
    window.addEventListener('resize', resizeEvents);
    init();
};

export default KGToggleNavigation;
