// UIKit Core
import UIkit from 'uikit/dist/js/uikit-core';
// Helpers
import { calcWinsize, breakpoint } from '../Helpers';

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

export default class Menu {
    constructor(el) {
        this.DOM = {
            el: el
        };

        if (!this.DOM.el) return;

        this.menuItems = [...this.DOM.el.children].filter(child => child.classList.contains('kg-menu__item--has-children'));
        this.submenuDropdowns = this.menuItems.map(item => item.querySelector('.kg-menu__submenu-dropdown'));
        this.backButtons = this.submenuDropdowns.map(item => item.querySelector('.kg-menu__submenu-dropdown__back-button'));
        this.menuItemParents = this.submenuDropdowns.map(item => item.querySelector('.kg-menu__submenu-dropdown__parent'));
        this.dropdowns = [];

        this.init();
    }

    // Dropdown
    hideSubmenuDropdown(dropdown) {
        this.DOM.el.classList.remove('is-submenu-open');
        dropdown.setAttribute('hidden', '');
        dropdown.setAttribute('aria-hidden', 'true');
    }

    showSubmenuDropdown(dropdown) {
        this.DOM.el.classList.add('is-submenu-open');
        dropdown.removeAttribute('hidden');
        dropdown.setAttribute('aria-hidden', 'false');
        document.querySelector('#kg-offcanvas-navigation').scrollTo(0, 0);
    }

    // item Events
    menuItemsEvents() {
        this.menuItems.forEach(item => {
            const link = item.firstChild;
            const dropdown = item.querySelector('.kg-menu__submenu-dropdown');
            link.addEventListener('click', (e) => {
                if (!dropdown) return;

                e.preventDefault();
                this.showSubmenuDropdown(dropdown);
            });
        });
    }

    // Back Button Events
    backButtonEvents() {
        this.backButtons.forEach(button => {
            const dropdown = button.closest('.kg-menu__submenu-dropdown');
            button.addEventListener('click', (e) => {
                this.hideSubmenuDropdown(dropdown);
            });
        });
    }

    // Dropdown
    initDropdown() {
        const $offcanvas = document.querySelector('#kg-offcanvas-navigation');

        this.submenuDropdowns.forEach(dropdown => {
            const ukDropdown = UIkit.dropdown(dropdown, {
                pos: 'bottom-justify',
                boundary: '.kg-header',
                boundaryAlign: true,
                animation: 'uk-animation-fade',
                duration: 300,
                delayHide: 300
            });
            this.dropdowns.push(ukDropdown);
        });
    }

    initMobile() {
        this.submenuDropdowns.forEach(dropdown => this.hideSubmenuDropdown(dropdown));
        this.menuItemsEvents();
        this.backButtonEvents();
        if (this.dropdowns.length) {
            this.dropdowns.forEach(dropdown => {
                dropdown.$destroy();
            });
        }
    }

    initDesktop() {
        [...this.backButtons, ...this.menuItemParents].forEach((el) => {
            el.setAttribute('aria-hidden', 'true');
            el.setAttribute('hidden', '');
        });
        this.initDropdown();
    }

    init() {
        if ( winsize.width > breakpoint.medium ) {
            this.initDesktop();
        } else {
            this.initMobile();
        }
    }
}
