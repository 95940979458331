/**
 * Breadcrumb
 */
const Breadcrumb = () => {
    let $breadcrumb = document.getElementById('breadcrumbs');
    let $breadcrumb_wrapper = document.querySelector('.kg-breadcrumb-wrapper');

    if ( !$breadcrumb || !$breadcrumb_wrapper ) {
        return false;
    }

    $breadcrumb_wrapper.prepend($breadcrumb);
    $breadcrumb_wrapper.parentNode.removeAttribute('hidden');
};

export default Breadcrumb;
